import { useMemo } from 'react'
import { Visible } from 'react-grid-system'
import { useDispatch, useSelector } from 'react-redux'

import Router from 'next/router'

import Icon from 'components/elements/Icon'
import { View } from 'components/helpers'
import { createSelector } from 'reselect'
import { isLogged } from 'services/AuthService'
import { RootState } from 'store/reducers'
import { disableCartAction, enableCartAction } from 'store/reducers/appReducer'
import { CardProductProps } from 'store/reducers/cartReducer'

import { Badge, CartBubble, CartView, Container } from './styles'

const cartItemsSelector = (state: RootState) => state.cart.items

const getCartTotalProducts = createSelector(cartItemsSelector, (items) => items.reduce((quantity: number, item: CardProductProps) => quantity + item.quantity, 0))

const Tabbar = () => {
  const dispatch = useDispatch()
  const state = useSelector<RootState, RootState>((state) => state)

  const onPressCart = () =>
    dispatch(enableCartAction())

  const quantity = useMemo(() => {
    return getCartTotalProducts(state)
  }, [state])

  const navigateTo = (page: string) => {
    return () => {
      dispatch(disableCartAction())
      Router.push(page)
    }
  }

  if (state.app.screenRegister === 'Start') return null

  const isFocused = (page: string) => {
    return window.location.pathname === page && !state.app.cartToggle
  }

  return (
    <Visible xs>
      <Container>
        <View onClick={navigateTo('/')}>
          <Icon name={isFocused('/') ? 'house-filled' : 'house'} size='sm' color={isFocused('/') ? 'red-primary' : 'black'} />
        </View>

        <View onClick={navigateTo('/ofertas')}>
          <Icon name={isFocused('/ofertas') ? 'sale-filled' : 'sale'} size='sm' color={isFocused('/ofertas') ? 'red-primary' : 'black'} />
        </View>

        <CartView>
          <CartBubble onClick={onPressCart}>
            <Icon name='shopping-cart-simple' size='sm' color='white' />

            {quantity ? (
              <Badge>
                <span>{quantity}</span>
              </Badge>
            ) : null}
          </CartBubble>
        </CartView>

        <View onClick={navigateTo(isLogged() ? '/pedidos' : '/signin')}>
          <Icon name={isFocused('/pedidos') ? 'receipt-filled' : 'receipt'} size='sm' color={isFocused('/pedidos') ? 'red-primary' : 'black'} />
        </View>

        <View onClick={navigateTo(isLogged() ? '/menu' : '/signin')}>
          <Icon name={isFocused('/menu') ? 'user-circle-filled' : 'user-circle'} size='sm' color={isFocused('/menu') ? 'red-primary' : 'black'} />
        </View>
      </Container>
    </Visible>
  )
}

export default Tabbar

import styled from 'styled-components'

export const Container = styled.div`
  position: fixed;
  z-index: 50;
  bottom: 0;
  left: 0;

  width: 100%;
  padding: 2rem;
  padding-bottom: 1.8rem;
  padding-top: 0.2rem;

  display: flex;
  align-items: center;
  justify-content: space-between;

  background: #ffffff;
  border-top: 1px solid #ECECEC;
`

export const CartView = styled.div`
  position: ralative;
  height: 50px;
  width: 56px;
`

export const CartBubble = styled.div`
  height: 100%;
  background-color: #EA2A2F;

  width: 56px;
  height: 56px;
  border-radius: 50px;

  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: -10px;

`

export const Badge = styled.div`
  width: 30px;
  height: 30px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 15px;
  background: #f2f2f2;

  position: absolute;
  top: -8px;
  right: -8px;

  span {
    font-size: 0.8rem;
  }
`

/* eslint-disable react-hooks/exhaustive-deps */
import { FC, Fragment, useCallback, useEffect, useState } from 'react'

import '@brainhubeu/react-carousel/lib/style.css'
import { Hidden, Visible } from 'react-grid-system'
import Svg from 'react-inlinesvg'
import { useDispatch, useSelector } from 'react-redux'

import Image from 'next/image'
import Link from 'next/link'
// import Router from 'next/router'

import Carousel, { Dots, autoplayPlugin } from '@brainhubeu/react-carousel'
import {
  BadgeDelivery,
  Box,
  CarouselResponsive,
  CookieWarning,
  Count,
  LinkCategory,
} from 'components/elements'
import { Button } from 'components/form'
import {
  Text,
  View,
  Scroll,
  Content,
  Jumbotron,
  Line,
} from 'components/helpers'
import { Footer, Header } from 'container'
import Tabbar from 'container/Tabbar'
import useHome from 'hooks/useHome'
import useIsMobile from 'hooks/useIsMobile'
import { useProduct } from 'hooks/useProduct'
import { getAvaliableStore } from 'services/address'
import { getToken } from 'services/AuthService'
import { RootState } from 'store/reducers'
import { setScreenAction } from 'store/reducers/appReducer'
import { setReadCookieAction } from 'store/reducers/configReducer'
import { isOnApp } from 'utils/helper/common'
import { numberFormat } from 'utils/helper/currency'
import { truncate } from 'utils/helper/string'

const destaques: any = [
  {
    name: 'atendimento-todos-os-dias',
    component: '',
    img: 'https://farmazon-v2-app-homolog.s3.sa-east-1.amazonaws.com/client/highlights/banners/1440x350.jpg',
    url: '',
  },
  {
    name: 'entrega-imediata',
    component: '',
    img: 'https://farmazon-v2-app-homolog.s3.sa-east-1.amazonaws.com/client/highlights/banners/1440x350.png',
    url: '',
  },
]

const Home: FC<any> = () => {
  const { cart, addressTemporary, config, user } = useSelector(
    (state: RootState) => state
  )
  const dispatch = useDispatch()
  const [slide, setSlide] = useState(0)

  const isMobile = useIsMobile()

  const isLogged = getToken()

  const { offers, categoriesMain } = useHome()

  useEffect(() => {
    if (user.address_selected?.pub_id) {
      getAvaliableStore(user.address_selected?.pub_id).then(({ data }) => {
        if (!data.is_avaliable) {
          dispatch(setScreenAction('NoAddress'))
        }
      })
    }
  }, [user.address_selected])

  const {
    categories,
    handleProductCategory,
    handleAddCart,
    handleAddOfferCart,
    handleIncrement,
    handleDecrement,
  } = useProduct()

  useEffect(() => {
    handleProductCategory()
    if (!isLogged && !addressTemporary.hasTemporaryAddress) {
      dispatch(setScreenAction('Start'))
    }
  }, [])

  const handleChangeSlide = (value: number) => setSlide(value)

  const inProductCart = useCallback(
    (idProduct: string) => {
      return cart.items.find((item) => item.pub_id === idProduct)
    },
    [cart]
  )

  const productQuantity = useCallback(
    (idProduct: string) => {
      return cart.items.find((item) => item.pub_id === idProduct)?.quantity ?? 0
    },
    [cart]
  )

  const handleReadCookie = () => dispatch(setReadCookieAction())

  const handleAddOffer = (offerId: string, product: any) => () =>
    dispatch(handleAddOfferCart(offerId, product))

  return (
    <View style={{ minHeight: '110vh' }}>
      <Header />
      <Hidden xs sm>
        <Carousel
          plugins={[
            'arrows',
            {
              resolve: autoplayPlugin,
              options: {
                interval: 5000,
              },
            },
          ]}
          animationSpeed={800}
          value={slide}
          slides={destaques}
          onChange={handleChangeSlide}
        >
          {destaques &&
            destaques.map((item: any, index: number) => (
              <CarouselResponsive key={index}>
                <Image
                  src={item.img}
                  width='100%'
                  height='100%'
                  alt={item.name}
                  priority
                  layout='fill'
                />
              </CarouselResponsive>
            ))}
        </Carousel>
        <View mt={1}>
          <Dots value={slide} onChange={handleChangeSlide} number={2} />
        </View>
      </Hidden>
      <Visible xs sm>
        <View mr={2} ml={2} mt={2} mb={1}>
          <img
            src='https://farmazon-v2-app.s3.sa-east-1.amazonaws.com/client/setup/banners/banner_1_320x120_1.jpg'
            width='100%'
            height='auto'
            alt='Destaque'
          />
        </View>
      </Visible>

      <Jumbotron className='cat'>
        {categories?.map((item, index) => (
          <Link href={`/categoria/${item.name}`} key={index}>
            <a className='cat__link'>
              <LinkCategory className='cat__block'>
                <Svg
                  src={item.image}
                  width={40}
                  height={40}
                  title={item.name}
                />
                <View mt={0.8}>
                  <Text size={14} fontWeight={500}>
                    {item.name}
                  </Text>
                </View>
              </LinkCategory>
            </a>
          </Link>
        ))}
      </Jumbotron>

      <Content paddingTop='0rem'>
        <div id='offers-container'>
          {offers?.length > 0 && (
            <>
              <View justify='center' mt={2}>
                <Text size={18} color='#EA2A2F' fontWeight={700}>
                  Ofertas em destaque
                </Text>
              </View>
              <View pt={2} pb={1} className='showcase'>
                {offers.map((item: any, index: number) => (
                  <Box key={index} className='showcase__item'>
                    <Link href={`/oferta/${item.slug}`} passHref>
                      <a className='showcase__link'>
                        <Image
                          src={item.product.img}
                          height={150}
                          width={150}
                          alt={item.product.name}
                          className='showcase__image'
                        />
                        <View mt={1.2} height='2rem'>
                          <Text
                            textAlign='left'
                            fontWeight={500}
                            color='#474747'
                          >
                            {truncate(item.product.name, 60)}
                          </Text>
                        </View>
                      </a>
                    </Link>

                    <View style={{ marginTop: 'auto', width: '100%' }}>
                      <View
                        direction='row'
                        align='center'
                        justify='space-between'
                        mb={1}
                        height='1rem'
                      >
                        <Text
                          textAlign='center'
                          size={10}
                          fontWeight={300}
                          color='#474747'
                          style={{ textDecoration: 'line-through' }}
                        >
                          {numberFormat(item.product.normal_price)}
                        </Text>
                        <Text
                          textAlign='center'
                          fontWeight={700}
                          color='#00997F'
                        >
                          {numberFormat(item.product.price)}
                        </Text>
                      </View>
                      {inProductCart(item.pub_id) ? (
                        <View height='2.7rem' align='center'>
                          <Count
                            quantity={productQuantity(item.pub_id)}
                            onIncrement={handleIncrement(item.pub_id)}
                            onDecrement={handleDecrement(item.pub_id)}
                          />
                        </View>
                      ) : (
                        <Button
                          label='Adicionar'
                          background='#3097E2'
                          onClick={handleAddOffer(item.pub_id, item.product)}
                        />
                      )}
                    </View>
                  </Box>
                ))}
              </View>
            </>
          )}
        </div>

        <Hidden xs>
          <View
            mt={2}
            mb={2}
            className='app-block'
            align='center'
            direction='row'
            justify='space-around'
            padding={1}
          >
            <Text size={25} fontWeight={700} color='#000000'>
              Leve toda a experiência Farmazon para o seu celular!
            </Text>
            <div className='app-block__apps'>
              <Text size={16} fontWeight={400} color='#FFFFFF'>
                Baixe o nosso aplicativo
              </Text>
              <div className='app-block__images'>
                <a
                  href='https://play.google.com/store/apps/details?id=br.com.farmazon.client&hl=pt_BR'
                  target='_blank'
                  rel='noreferrer'
                >
                  <Image src='/banner/android.png' height={43} width={151} />
                </a>
                <a
                  href='https://apps.apple.com/br/app/farmazon/id1453428847'
                  target='_blank'
                  rel='noreferrer'
                >
                  <Image src='/banner/ios.png' height={44} width={160} />
                </a>
                <Image src='/banner/logo-small.png' height={46} width={46} />
              </div>
            </div>
          </View>
        </Hidden>

        <div id='category-container'>
          {categoriesMain &&
            categoriesMain.map(({ name, data }: any, index: number) => (
              <Fragment key={index}>
                <View direction='row' mt={2}>
                  <Text size={18} color='#EA2A2F' fontWeight={700}>
                    {name}
                  </Text>
                  <Link href={`/categoria/${name}`}>
                    <Text className='subtitle__link'>ver tudo</Text>
                  </Link>
                </View>
                <View pt={2} pb={1}>
                  <Scroll className='showcase'>
                    {data?.map((item: any, index: number) => (
                      <Box key={index} className='showcase__item'>
                        <Link href={`/produto/${item.slug}`} passHref>
                          <a className='showcase__link'>
                            <Image
                              src={item.img}
                              height={150}
                              width={150}
                              alt={item.name}
                              className='showcase__image'
                            />
                            <View mt={1.2} height='2rem'>
                              <Text
                                textAlign='left'
                                fontWeight={500}
                                color='#474747'
                              >
                                {truncate(item.name, isMobile ? 30 : 60)}
                              </Text>
                            </View>
                          </a>
                        </Link>

                        <View style={{ marginTop: 'auto', width: '100%' }}>
                          <View mb={0.5}>
                            <Line type='dashed' />
                          </View>

                          <View direction='row'>
                            {item.is_available ? (
                              <BadgeDelivery />
                            ) : (
                              <BadgeDelivery background='#de8d40' />
                            )}
                            <Text
                              size={12}
                              fontWeight={300}
                              mb={0.3}
                              color='#474747'
                            >
                              {item.is_available
                                ? 'Entrega rápida'
                                : 'Indisponível na região'}
                            </Text>
                          </View>

                          <View
                            direction='row'
                            align='center'
                            justify='space-between'
                            mb={1}
                          >
                            <div className='flex flex-row'>
                              {item?.characteristic?.prescription && (
                                <>
                                  <div className='w-4'>
                                    <BadgeDelivery background='#000000' />
                                  </div>
                                  <Text
                                    size={12}
                                    fontWeight={300}
                                    color='#474747'
                                  >
                                    Venda sob prescrição médica
                                  </Text>
                                </>
                              )}
                            </div>

                            <View>
                              <View direction='row' align='center' mt={0.2}>
                                {/* <Image src='/icon/icon-price-up.svg' height={18} width={18} /> */}
                                <Text
                                  size={16}
                                  fontWeight={500}
                                  color='#474747'
                                  ml={0.8}
                                >
                                  {numberFormat(item.max_price)}
                                </Text>
                              </View>
                            </View>
                          </View>

                          {inProductCart(item.pub_id) ? (
                            <View height='2.7rem' align='center'>
                              <Count
                                disabledIncrement={
                                  !!(
                                    item.available_quantity &&
                                    item.available_quantity ===
                                      productQuantity(item.pub_id)
                                  )
                                }
                                quantity={productQuantity(item.pub_id)}
                                onIncrement={handleIncrement(item.pub_id)}
                                onDecrement={handleDecrement(item.pub_id)}
                              />
                            </View>
                          ) : (
                            <Button
                              label='Adicionar'
                              background='#3097E2'
                              onClick={handleAddCart(item)}
                              disabled={!item.is_available}
                            />
                          )}
                        </View>
                      </Box>
                    ))}
                  </Scroll>
                </View>
              </Fragment>
            ))}
        </div>
      </Content>

      <Footer />

      {!(window as any).ReactNativeWebView &&
        !config.enableCookie &&
        isLogged &&
        !isOnApp() && <CookieWarning onClick={handleReadCookie} />}

      <Tabbar />
    </View>
  )
}

export default Home

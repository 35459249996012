import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { isLogged } from 'services/AuthService'
import {
  getProductCategoryByQuery,
  getProductOffersByAddressId,
  getProductOffersByLatLng
} from 'services/product'
import { RootState } from 'store/reducers'
import { getUserLocalLocation } from 'utils/helper/location'

const useHome = () => {
  const { user, addressTemporary } = useSelector((state: RootState) => state)

  const [offers, setOffers] = useState<any>(null)
  const [categoriesMain, setCategories] = useState<any>([])

  const isLoggedUser = isLogged()

  const handleGetProductOffers = () => {
    if (isLoggedUser) {
      const pubId = user.address_selected?.pub_id ?? ''
      getProductOffersByAddressId(pubId, user?.setup?.customer?.offer_distance)
        .then(({ data: ofetas }) => {
          setOffers(ofetas)
        })
        .catch((error: any) => error)
      return
    }

    if (addressTemporary.hasTemporaryAddress) {
      getProductOffersByLatLng({
        lat: addressTemporary.lat,
        lng: addressTemporary.lng,
        range: user?.setup?.customer?.offer_distance
      })
        .then(({ data: ofetas }) => {
          setOffers(ofetas)
        })
        .catch((error: any) => error)
    }
  }

  const handleOnScroll = () => {
    handleGetProductOffers()

    const location = getUserLocalLocation(user, addressTemporary)
    const categories = localStorage.getItem('CATEGORY') ?? false
    if (categories) {
      const categoriesNames = JSON.parse(categories)
      setCategories([])

      for (const index in categoriesNames) {
        const category = categoriesNames[index]
        getProductCategoryByQuery(category.name, 5, location)
          .then((result) => {
            setCategories((state: any) => [...state, {
              name: category.name,
              data: result.data
            }])
          })
      }
    }
  }

  useEffect(() => {
    handleOnScroll()
  }, [addressTemporary])

  return {
    offers,
    categoriesMain,
    handleGetProductOffers
  }
}

export default useHome
